<
<template>
  <el-dialog
    class="source"
    title="切换税源地（非业务必须谨慎操作）"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    width="800px"
  >
    <div class="source-title">
      <h3>操作须知：</h3>
      <p style="color: red">
        1、切换税源地前须把新税源地配置信息完善，对应配置的电签模板若和旧税源地一致则无需重签，
        反之则需要重签，请提前跟商户沟通好（请留意，切换成功后系统会再次判断是否重签并提示）
      </p>
      <p style="color: red">2、切换税源地后与商户的合同必须重签，请提前跟商户沟通好</p>
      <p style="color: red">3、切换税源地后生成对应的企业网银账户，请提前跟商户沟通好</p>
      <p style="color: red">4、切换税源地后由运营手动绑定该商户的开票税目（无需审核）</p>
    </div>
    <div v-if="checkStateContent.length">
      <div v-if="checkStateContent[0].checkState == 1" class="source-remaind">
        <span>不可切换，存在处理中结算单：{{ checkStateContent[0].stringWorkPlanNoList }}</span>
      </div>
      <div v-if="checkStateContent[1].checkState == 1" class="source-remaind">
        <span>不可切换，账户{{ checkStateContent[1].accountNumber }}存在余额，请先联系商户全部提现</span>
      </div>
    </div>
    <div v-if="checkStateContent.length" class="source -form">
      <el-form class="formStyle" :model="form" :rules="rules" ref="vForm" label-width="120px">
        <el-form-item label="商户名称" prop="merchantName">
          <el-input style="width: 300px" disabled v-model="form.merchantName" />
        </el-form-item>
        <el-form-item label="原税源地" prop="supplierName">
          <el-input style="width: 300px" disabled v-model="form.supplierName" />
        </el-form-item>
        <template v-if="checkStateContent[0].checkState != 1 && checkStateContent[1].checkState != 1">
          <el-form-item label="新税源地" prop="supplierId">
            <el-select @change="changeSup" v-model="form.supplierId" placeholder="请选择" style="width: 300px">
              <el-option v-for="(item, index) in taxSourcesOptions" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联配置" prop="configId">
            <el-select @change="changeConfig" v-model="form.configId" placeholder="请选择" style="width: 300px">
              <el-option v-for="(item, index) in configOptions" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更换任务税目" prop="taxId">
            <el-cascader
              style="width: 300px"
              v-model="form.taxId"
              :options="taxOptions"
              :props="{ value: 'taxId', label: 'taxName', emitPath: false }"
              :show-all-levels="false"
              placeholder="请选择"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.taxName }}</span>
                <!-- <span> {{ data.taxCode }} </span> -->
              </template>
            </el-cascader>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <span v-if="checkStateContent.length" slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button v-if="checkStateContent[0].checkState != 1 && checkStateContent[1].checkState != 1" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formsMerchantAccount } from '@/configs/forms'
export default {
  name: 'changeTaxSources',
  data() {
    return {
      show: false,
      itemOptions: [],
      rules: {
        taxId: [{ required: true, message: '请选择绑定税目', trigger: 'change' }],
        supplierId: [{ required: true, message: '请选择新税源地', trigger: 'change' }],
        configId: [{ required: true, message: '请选择关联配置', trigger: 'change' }]
      },
      form: {},
      rowInfo: {},
      checkStateContent: [],
      taxSourcesOptions: [],
      configOptions: [],
      taxOptions: []
    }
  },
  methods: {
    open(obj = {}) {
      this.form = {}
      this.checkStateContent = []
      this.form.merchantName = obj.merchantName
      this.form.supplierName = obj.supplierName
      this.rowInfo = Object.assign({}, obj)
      this.checkState()
      this.getSupplierOptions()
      this.show = true
    },
    async checkState() {
      const workPlan = await this.$api.CheckGetBeingWorkPlan({
        MerchantId: this.rowInfo.merchantId
      })
      // const account = await this.$api.CheckGetIsNotZeroAccount({
      //   MerchantId: this.rowInfo.merchantId,
      // });
      const account = {}
      this.checkStateContent.push(workPlan, account)
    },
    async getSupplierOptions() {
      const res = await this.$api.GetSupplierListByMerchantId({
        merchantId: this.rowInfo.merchantId
      })
      if (res) {
        this.taxSourcesOptions = res.list.map((item) => {
          return {
            label: item.supplierName,
            value: item.supplierId
          }
        })
      }
    },
    async changeSup() {
      if ('configId' in this.form) {
        delete this.form.configId
        delete this.form.taxId
      }
      const res = await this.$api.GetMerchantConfigCode({
        MerchantId: this.rowInfo.merchantId,
        SupplierId: this.form.supplierId
      })
      if (res) {
        this.configOptions = res.list.map((item) => {
          return {
            label: item.configCode,
            value: item.configId
          }
        })
      }
    },
    async changeConfig() {
      console.log(this.form.configId)
      const res = await this.$api.GetTaxListByMerchantConfigId({
        configId: this.form.configId
      })
      console.log(res)
      const result = this.treeOptions(res.list, null)
      this.taxOptions = result
    },
    // 扁平数据转树状结构
    treeOptions(data, id, level = 1) {
      const treeList = []
      data.forEach((item) => {
        if (item.taxLevel == level) {
          if (item.taxBaseId == id) {
            const children = this.treeOptions(data, item.taxId, level + 1)
            if (children.length > 0) {
              treeList.push({ ...item, children })
            } else {
              treeList.push({ ...item })
            }
          }
        }
      })
      return treeList
    },
    async submit() {
      console.log(this.form)
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$api.SwitchMerchantConfig(Object.assign({ merchantId: this.rowInfo.merchantId }, this.form))
          console.log(res)
          if (res) {
            this.$message({
              message: res.marked,
              type: 'success'
            })
            this.$emit('submit')
            this.show = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.source {
  ::v-deep .el-dialog__body {
    padding-top: 10px;
  }
  ::v-deep .el-dialog__title {
    font-weight: 600;
    font-size: 16px;
  }
}
.source-title {
  border-bottom: 1px solid #ccc;
}
.source-remaind {
  margin-top: 10px;
  text-align: center;
  background: #fff0ef;
  border: 1px solid #ff9994;
  color: #ff202f;
  padding: 10px 0;
  max-height: 70px;
  overflow-y: auto;
}
.formStyle {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
