<template>
  <el-dialog title="添加" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
    <!-- 内容区域 -->
    <div class="dialog-wrap" v-if="show" style="height: 200px; padding-right: 10px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '160px' }"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
const fromLabels = [
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'change' }]
    },
    config: {
      placeholder: '请输入商户号/商户名称',
      filterable: true,
      style: { width: '100%' }
    }
  },
  {
    cmp: 'select',
    label: '所属税源地',
    prop: 'supplierId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'change' }]
    },
    config: {
      placeholder: '请输入税源地编号/税源地名称',
      filterable: true,
      style: { width: '100%' }
    }
  },
  {
    cmp: 'upload',
    label: '交付物',
    prop: 'certificateUrl',
    config: { type: 'all' },
    itemConfig: {
      rules: [{ required: true, message: '请上传交付物', trigger: 'change' }]
    }
  }
]
export default {
  props: {},
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels,
      form: {}
    }
  },
  mounted() {},
  methods: {
    // 数据清空
    clear() {
      this.btnloadSubmit = false
      this.form = {}
    },
    async open() {
      this.clear()
      this.getMerchantOptions()
      this.getSupplierOptions()
      this.show = true
    },
    // label字段添加
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.forEach((item) => {
        if (item.prop === 'merchantId') {
          item.options = v
        }
      })
    },
    async getSupplierOptions() {
      let v = []
      let result = await this.$api.GetSupplierInfoCodeName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.supplierId,
          label: `【${item.supplierCode}】${item.supplierName}`
        })
      })
      this.fromLabels.forEach((item) => {
        if (item.prop === 'supplierId') {
          item.options = v
        }
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        let res = await this.$api.AddTaskCertificate(Object.assign({}, this.form)).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>
