<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTaskManagePageList" :searchList="searchList">
      <el-form-item v-if="$hasAccess('000605')" slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.CreatTask.open()">发布任务</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="200" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button
            type="text"
            v-if="$hasAccess('000603') && row.auditStatus == 1 && (row.taskStateEnum == 1 || row.taskStateEnum == 2)"
            @click="breakTax(row)"
            >终止</el-button
          >
          <el-button type="text" v-if="$hasAccess('000602')" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button type="text" v-if="$hasAccess('000601') && row.auditStatus == 0 && row.taskStateEnum == 0" @click="$refs.Audit.open(row)"
            >任务审核</el-button
          >
          <el-button type="text" v-if="$hasAccess('000601') && row.auditStatus == 1" @click="$refs.Edit.open(row.id)">编辑</el-button>
          <el-button
            type="text"
            v-if="$hasAccess('000604') && row.auditStatus == 1 && (row.taskStateEnum == 1 || row.taskStateEnum == 2)"
            @click="GetSelectMerchantOptions(row)"
            >更换税目</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Detail ref="Detail" />
    <Edit ref="Edit" />
    <Audit ref="Audit" @updateList="$refs.vTable.refreshTable()" />
    <CreatTask ref="CreatTask" @submit="asyncRefreshTable()" />
    <el-dialog :visible.sync="showMerchantTaxId" title="任务审核" width="30%">
      <div>
        <el-form :model="form" :rules="rules" ref="vForm" label-width="120px">
          <el-form-item label="绑定税目" prop="merchantTaxId">
            <el-cascader
              v-model="form.merchantTaxId"
              :options="itemOptions"
              :props="{ value: 'merchantTaxId', label: 'merchantTaxName', emitPath: false }"
              :show-all-levels="false"
              placeholder="请选择"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.merchantTaxName }}</span>
                <span> {{ data.taxCode }} </span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="showMerchantTaxId = !showMerchantTaxId">取消</el-button>
        <el-button type="primary" @click="submitTax">确认</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog width="30%" title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button @click="showReason = !showReason">取消</el-button>
        <el-button @click="submit(2)" type="primary">确认</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import CreatTask from './creatTask'
import { tableTask } from '@/configs/tables'
import { searchTask } from '@/configs/searches'
import Audit from './audit'
import Detail from './detail'
import Edit from './edit'
export default {
  components: { Edit, Detail, Audit, CreatTask },
  data() {
    return {
      tableLabels: tableTask,
      searchList: searchTask,
      show: false,
      showMerchantTaxId: false,
      form: {},
      rules: {
        merchantTaxId: [{ required: true, message: '请选择绑定税目', trigger: 'change' }]
      },
      itemOptions: [],
      taskId: ''
    }
  },
  methods: {
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    breakTax(e) {
      this.$confirm('是否终止当前任务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(async () => {
        const res = await this.$api.StopTask({ taskId: e.id, taskState: 3 })
        if (res) {
          this.asyncRefreshTable()
          this.$message.success('终止成功')
        }
      })
    },
    // 获取税目数据源
    async GetSelectMerchantOptions(row) {
      this.form.merchantTaxId = row.merchantTaxId
      this.taskId = row.id
      this.form = {}
      const res = await this.$api.GetSelectMerchantTaxId({ taskId: row.id })
      const result = this.treeOptions(res.list, null)
      console.log(result)
      this.showMerchantTaxId = true
      this.itemOptions = result
    },
    // 扁平数据转树状结构
    treeOptions(data, id, level = 1) {
      const treeList = []
      data.forEach((item) => {
        if (item.taxLevel == level) {
          if (item.taxBaseId == id) {
            const children = this.treeOptions(data, item.merchantTaxId, level + 1)
            if (children.length > 0) {
              treeList.push({ ...item, children })
            } else {
              treeList.push({ ...item })
            }
          }
        }
      })
      return treeList
    },
    submitTax() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          const res = await this.$api.UpdateTaskTax({
            taskId: this.taskId,
            ...this.form
          })
          this.$message.success('已更改')
          this.showMerchantTaxId = false
          this.asyncRefreshTable()
        }
      })
    }
  }
}
</script>
