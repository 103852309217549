<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetMerchantConfigPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('007400')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.DialogMerchantConfigure.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('007401')" type="text" @click="$refs.DialogMerchantConfigure.open(row)">管理</el-button>
          <el-button v-if="$hasAccess('007402')" type="text" @click="$refs.DialogOpen.open(row)">通道</el-button>
          <el-button v-if="$hasAccess('007403') && row.useState == 1" type="text" @click="apply(row)">应用</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <DialogMerchantConfigure ref="DialogMerchantConfigure" @submit="$refs.vTable.refreshTable()"></DialogMerchantConfigure>
    <DialogOpen ref="DialogOpen"></DialogOpen>
  </div>
</template>

<script>
import DialogMerchantConfigure from './configEdit'
import DialogOpen from './configChannel'

import { formatStatus, kvBM, kvContractMode, kvContractScheme10, kvContractScheme20, kvContractScheme30 } from '@/configs/formatters'
const tableLabels = [
  { label: '配置编号', prop: 'configCode' },
  { label: '商户号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  {
    label: '业务类型',
    prop: 'businessType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvBM, cellValue, ' ')
    }
  },
  {
    label: '商户使用状态',
    prop: 'useState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未使用' },
          { key: 1, label: '使用中' }
        ],
        cellValue,
        ' '
      )
    }
  },
  {
    label: '税源地名称',
    prop: 'supplierName',
    formatter: (row, column, cellValue, index) => {
      return cellValue + ' ' + row.supplierCode
    }
  },
  {
    label: '账户余额',
    prop: 'accountBalance',
    cmp: 'money'
  },
  {
    label: '费率类型',
    prop: 'isPiecewise',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '固定费率' },
          { key: 1, label: '阶梯费率' }
        ],
        cellValue,
        ' '
      )
    }
  },
  {
    label: '签约校验',
    prop: 'isSigned',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '开启' },
          { key: 1, label: '关闭' }
        ],
        cellValue,
        ' '
      )
    }
  },
  {
    label: '签约类型',
    prop: 'contractMode',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvContractMode, cellValue)
    }
  },
  {
    label: '签约方案',
    prop: 'contractScheme',
    formatter: (row, column, cellValue, index) => {
      if (row.contractMode == 10) return formatStatus(kvContractScheme10, cellValue)
      if (row.contractMode == 20) return formatStatus(kvContractScheme20, cellValue)
      if (row.contractMode == 30) return formatStatus(kvContractScheme30, cellValue)
      return '未知'
    }
  },
  {
    label: '签约模板',
    prop: 'peopleTemplateName'
    // formatter: (row, column, cellValue, index) => {
    //   return cellValue + ' ' + row.peopleTemplateName
    // }
  },
  // { label: '余额', prop: 'accountBalance' },
  { label: '创建时间', prop: 'createTime' }
]
const searchList = [
  {
    cmp: 'input',
    prop: 'MerchantName',
    label: '商户',
    config: {
      placeholder: '请输入商户名称/商户号',
      style: 'width: 300px'
    }
  },
  {
    cmp: 'input',
    prop: 'SupplierName',
    label: '税源地',
    config: {
      placeholder: '请输入税源地名称/税源地编号',
      style: 'width: 300px'
    }
  },
  {
    cmp: 'select',
    prop: 'ContractMode',
    label: '签约类型',
    options: kvContractMode
  }
]
export default {
  components: {
    DialogMerchantConfigure,
    DialogOpen
  },
  data() {
    return {
      tableLabels: tableLabels,
      searchList: searchList
    }
  },
  methods: {
    // 应用
    apply(item) {
      this.$confirm('是否应用此配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await this.$api.ApplyMerchantConfigInfo({
            id: item.id
          })
          if (res) {
            this.$message.success('应用成功')
            this.$refs.vTable.refreshTable()
          }
        })
        .catch(() => {})
    }
  }
}
</script>
