<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTaskCertificatePageList" :searchList="searchList">
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('100060001')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- 附加栏 -->
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="download(row)">下载</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="asyncRefreshTable" />
  </div>
</template>
<script>
import { downloadUrl } from '@/utils'
import Edit from './edit.vue'
const tableLabels = [
  { prop: 'memberCode', label: '商户号' },
  { prop: 'merchantName', label: '商户名称' },
  { prop: 'supplierCode', label: '税源地编号' },
  { prop: 'supplierName', label: '税源地名称' },
  { prop: 'createTime', label: '创建时间' }
]
const searchList = [
  { cmp: 'input', label: '商户', prop: 'MerchantTxtSearch', config: { placeholder: '请输入商户号或商户名称' } },
  { cmp: 'input', label: '税源地', prop: 'SupplierTxtSearch', config: { placeholder: '请输入税源地编号或税源地名称' } }
]
export default {
  components: { Edit },
  data() {
    return {
      tableLabels,
      searchList
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    // 下载合同
    async download(row) {
      if (row.certificateUrl) {
        downloadUrl(row.certificateUrl)
      } else {
        this.$message.warning('未找到交付物')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
